<template>
  <div class="about-block-section">
      <div class="container">
          <VerticalDivider 
            :style = "divider"
          />
          <div :style="direction" class="row">
              <div class="col-12 col-lg-6">
                  <h3>{{ title }}</h3>
                  <HorizontalDivider 
                    style="margin: 15px 0"
                  />
                  <p>{{ description }}</p>
              </div>
              <div :style="align" class="d-flex col-12 col-lg-6 justify-content-center">
                  <img class="img-fluid" :src="src" :alt="alt" >
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import HorizontalDivider from '@/components/HorizontalDivider.vue'
import VerticalDivider from '@/components/VerticalDivider.vue'


export default {
    props: ['divider','align','direction', 'title', 'description', 'src', 'alt'],
    components: { HorizontalDivider, VerticalDivider }

}
</script>

<style>
    .about-block-section{
        margin: 30px 0;
    }

    .about-block-section .row{
        align-items: center;
    }

    .about-block-section .row h3{
        font-size: 2.5rem;
        font-weight: 800;
    }

    .about-block-section .row p{
        font-size: 1.375rem;
    }

    .about-block-section img {
        width: 90%;
        border-radius: 10px;
        border: 2px solid #fff;
    }

     @media (max-width: 992px) {
        .about-block-section .row{
            flex-direction: column-reverse !important;
            justify-content: center;
        }

        .about-block-section .row h3{
            font-size: 2rem;
            font-weight: 800;
        }

        .about-block-section .row p{
            font-size: 1rem;
            margin-bottom: 0;
        }

        .about-block-section img {
            width: 100%;
            margin-bottom: 30px;
        }
     }
</style>