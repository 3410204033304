<template>
  <div class="hero-bg">
    <h1>We’re Feeliate 
        <br> 
        Network 
    </h1>
    <p>based in Amsterdam</p>
    <div class="hero-div"></div>
    <div class="col-lg-8 mx-auto">
      <div class="d-grid gap-3 d-sm-flex justify-content-sm-center flex-lg-row flex-sm-column">
        <a href="https://feelrobotics.hasoffers.com/" class="btn btn-lg px-4 hero-btn hero-aff-btn">BECOME AN AFFILIATE</a>
        <a href="https://feelrobotics.hasoffers.com/" class="btn btn-lg px-4 hero-btn hero-adv-btn">BECOME AN ADVERTISER</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {

}
</script>

<style>
    .hero-bg{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 660px;
        background: center / cover no-repeat url(../assets/img/home-hero-bg.jpg);
        width: 100%;;
        padding-top: 90px;
        margin-top: -90px;
        transition: all ease-in-out 0.15s;
        text-align: center;
    }

    .hero-bg h1{
        font-size: 5rem;
        font-weight: 900;
        color: #fff;
        text-shadow: 3px 3px 4px rgb(30, 30, 30);
    }

    .hero-bg p{
        font-family: 'Dancing Script', cursive;
        font-size: 3.5rem;
        text-shadow: 3px 3px 4px rgb(30, 30, 30);
        margin: 0;
    }

    .hero-bg .hero-div{
        content: "";
        background-color: #EA2050;
        width: 70px;
        height: 3px;
        margin: 50px 0;
    }

    .hero-bg .hero-btn{
        font-family: "Poppins", sans-serif;
        font-size: 1.5rem;
        transition: all ease-in 0.2s;
        background-color: #EA2050;
        color: #fff;
        width: 370px;
    }

    .hero-bg .hero-btn:hover{
        background-color: #fff;
        color: #EA2050;
    }

    @media (max-width: 1024px) {
      .hero-bg .hero-btn {
        width: 350px;
        margin: 0 auto;
      }
    }

    @media (max-width: 414px) {
      .hero-bg h1{
        font-size: 3rem;
    }

    .hero-bg p{

        font-size: 3rem;
    }

      .hero-bg .hero-btn {
        width: 290px;
        font-size: 1.2rem;
      }
    }


</style>