<template>
  <div id="offers" class="offers-section">
      <div class="container">
          <div class="offers-header-wrp">
              <VerticalDivider />
              <h3 class="offers-header">Our Offers</h3>
              <p class="offers-slogan">With our offers, we bring touch to the internet.</p>         
          </div>
          <div class="row gx-2 gy-2 flex-sm-row flex-column justify-content-md-center">
              <Offer />
          </div>
      </div>
  </div>
</template>

<script>
import Offer from '@/components/Offer.vue'
import VerticalDivider from '@/components/VerticalDivider.vue'

export default {
    components: {
        Offer,
        VerticalDivider
    },
   
     data() {
       return {

       }
    }

}
</script>

<style>
    .offers-section{
        background-color: #000;
    }

    .offers-header-wrp{
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .offers-header{
        font-size: 2rem;
        font-weight: 900;
        margin-bottom: 20px;
    }

    .offers-slogan{
        font-size: 1.5rem;
        font-weight: 900;
        color: #EA2050;
        margin-bottom: 45px;
    }

     @media (max-width: 414px) {
        .vertical-devider{
            height: 30px;
            margin-bottom: 30px;
        }

        .offers-header{
            font-size: 1.5rem;
            margin-bottom: 10px;
        }

        .offers-slogan{
            font-size: 1.2rem;
            margin-bottom: 10px;
        }
     }

</style>