<template>
    <div class="col-lg-3 col-md-10 col-12">
        <div class="card benefits-card">
            <div class="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                <img class="img-fluid" :src="src" />
            </div>
            <div class="card-body text-center">
                <h5 class="card-title">{{ title }}</h5>
                <p class="card-text">
                    {{ text }}
                </p>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    props: ['src', 'title', 'text'],
}
</script>

<style>
    .benefits-card{
        background-color: #1D1D1D !important;
        overflow: hidden;
    }

    .card-title{
        color: #EA2050;
    }

    .card-body{
        margin: 20px 0;
    }
</style>