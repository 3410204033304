<template>
  <div class="comments-section">
          <div class="container">
              <div class="offers-header-wrp">
                  <VerticalDivider />
                  <h3 class="offers-header">Our partners</h3>
                  <p class="offers-slogan">Lorem ipsum dolor sit amet, consecteur adipiscing elit.</p>
              </div>
              <div class="row align-items-center justify-content-center">
                 <div class="col-lg-3 col-md-10 col-12 gy-3 text-center"
                    v-for="partner in partners"
                    :key="partner.id">
                    <img 
                    class="img-fluid"
                    :src="partner.src" 
                    :alt="partner.alt"
                    >
                 </div>
              </div>
          </div>

</div>
 
</template>

<script>
import VerticalDivider from '@/components/VerticalDivider.vue'


export default {
    components: {
        VerticalDivider,
    },
    data () {
        return {
            partners:[
                {
                    id:1,
                    src: require('@/assets/img/kiiroo-logo.png')
                },
                {
                    id:2,
                    src: require('@/assets/img/nextdoorcam-logo.png')
                },
                {
                    id:3,
                    src: require('@/assets/img/feelhey-logo.png')
                },
                {
                    id:4,
                    src: require('@/assets/img/feelme-logo.png')
                },
                {
                    id:5,
                    src: require('@/assets/img/ufeel-logo.png')
                },
                {
                    id:6,
                    src: require('@/assets/img/ufeel-logo.png')
                },
                {
                    id:7,
                    src: require('@/assets/img/ufeel-logo.png')
                },
            ]
        }
    }
}
</script>

<style>

    @media (max-width: 414px) {
        .comments-section img{
            width: 40%;
        }
    }

</style>