<template>
    <div class=" col-12 col-md-4">
        <div class="img-wrp">
            <img class="img-fluid" :src="src" :alt="alt">
        </div>
        <div class="text-wrp d-flex text-center flex-column">
            <h5>{{ name }}</h5>
            <p>{{ position }}</p>
        </div>
    </div> 
</template>

<script>
export default {
    props: ['src', 'alt', 'name', 'position']

}
</script>

<style>
    .text-wrp{
        margin: 30px 0;
    }

    .text-wrp h5{
        font-size: 1.75rem;
        font-weight: 800;
    }

    .text-wrp p{
        font-size: 1.5rem;
        color: #EA2050;
    }
</style>