<template>
  <div id="affiliates" class="benefits-section">
      <div class="container">
        <VerticalDivider />
        <div class="benefits-header">
          <HorizontalDivider />
          <div class="benefits-tittle-wrp">
            <h3>Affiliates</h3>
            <a class="btn sign-btn" href="https://feelrobotics.hasoffers.com/">Sign up now</a>
          </div>
          <div class="benefits-slogan">
            We know how to monetize your traffic  
          </div>
          <p class="benefits-description">
            Curabitur gravida placerat tellus, vitae rutrum eros. Aenean cursus vel nulla vel 
            sagittis. Suspendisse tempus viverra commodo.  
          </p>
        </div>
        <div class="benefits-body">
          <div class="row gx-3 gy-2 flex-sm-row flex-column justify-content-md-center">
            <BenefitsCard 
              v-for="benefit in benefits"
              :key ="benefit.id"
              :src ="benefit.src"
              :title="benefit.title"
              :text="benefit.text" 
            ></BenefitsCard>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import BenefitsCard from '@/components/BenefitsCard.vue'
import VerticalDivider from '@/components/VerticalDivider.vue'
import HorizontalDivider from '@/components/HorizontalDivider.vue'


export default {
  components: { BenefitsCard, VerticalDivider, HorizontalDivider },
      data() {
       return {
           benefits: [{
             id: 1,
             src: require('@/assets/img/benefit-payout.jpg'),
             title: '100% Payouts',
             text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla lobortis faucibus purus idvestibulum. Mauris ut mi a mauris placerat"
           }, {
             id: 2,
             src: require('@/assets/img/benefit-creatives.jpg'),
             title: 'Ready-use creatives',
             text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla lobortis faucibus purus idvestibulum. Mauris ut mi a mauris placerat"
           }, {
             id: 3,
             src: require('@/assets/img/benefit-statistic.jpg'),
             title: 'Detailed statistics',
             text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla lobortis faucibus purus idvestibulum. Mauris ut mi a mauris placerat"
           },
           {
             id: 4,
             src: require('@/assets/img/benefit-statistic.jpg'),
             title: 'Detailed statistics',
             text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla lobortis faucibus purus idvestibulum. Mauris ut mi a mauris placerat"
           }]
       }
    }

}
</script>

<style>
    .benefits-tittle-wrp h3{ 
        font-size: 2rem;
        font-weight: 900;
        margin-bottom: 20px;
    }

    .benefits-slogan{
        font-size: 1.5rem;
        font-weight: 900;
        color: #EA2050;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    .benefits-tittle-wrp{
      display: flex;
      justify-content: space-between;
    }

    .benefits-tittle-wrp .sign-btn{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #EA2050;
      text-transform: uppercase;
      color: #fff;
      padding: 7px 20px;
      height: 46px;
      font-size: 1.25rem;
      font-weight: 600;
      transition: all ease-in 0.2s;
    }

    .benefits-tittle-wrp .sign-btn:hover{
      background-color: #fff;
      color: #EA2050;
    }

    .benefits-description{
      font-size: 1.25rem;
      margin-bottom: 30px;
    }

     @media (max-width: 992px) {
       .benefits-tittle-wrp{
         flex-direction: column;
         margin-bottom: 20px;
       }

        .benefits-tittle-wrp .sign-btn{
          width: 30%;
        }
     }

    @media (max-width: 414px) {
        .benefits-tittle-wrp .sign-btn{
          font-size: 1.2rem;
          width: 100%;
        }

        .benefits-tittle-wrp h3{
            font-size: 1.5rem;
            margin-bottom: 10px;
        }

        .benefits-slogan{
            font-size: 1.2rem;
            margin-bottom: 10px;
        }

        .benefits-description{
          font-size: 1rem;
        }
     }
</style>