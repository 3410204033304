<template>
<nav class="navbar navbar-expand-lg navbar-feeliate">
  <div class="container">
      <router-link class="navbar-brand" :to="{ name: 'Home' }">
        <img class="img-fluid" src="../assets/img/feeliate-logo.svg" width="202" alt="feeliate logo">
      </router-link>
    <button class="navbar-toggler navbar-toggler-btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
      </svg>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mb-2 mb-lg-0 navbar-nav-feeliate">
        <li class="nav-item">
          <router-link class="nav-link" :to="{ name: 'About' }">About</router-link>
        </li>
        <li class="nav-item">
          <a @click="scroll('#offers')" class="nav-link" href="#offers">
            Offers
          </a>
        </li>
        <li class="nav-item">
          <a @click="scroll('#affiliates')" class="nav-link" href="#affiliates">
            Affiliates
          </a>
        </li>
        <li class="nav-item">
          <a @click="scroll('#advertisers')" class="nav-link" href="#advertisers">
            Advertisers
          </a>
        </li>
        <li class="nav-item">
          <a @click="scroll('#footer')" class="nav-link" href="#footer">
            Contact
          </a>
        </li>
      </ul>
      <div class="d-flex nav-btn-wrp">
        <a href="https://feelrobotics.hasoffers.com/" class="btn nav-btn btn-signup">sign up</a>
        <a href="https://feelrobotics.hasoffers.com/" class="btn nav-btn btn-login">login</a>
      </div>
    </div>
  </div>
</nav>
</template>

<script>
export default {
  data() {
    return {
    }
  },
 methods: {
    
    scroll(id) {  
      document.getElementById(id).scrollIntoView({
        behavior: "smooth"
      });
    }
  }
}
</script>

<style>

  .navbar-nav-feeliate{
    width: 75%;
    justify-content: center;
  }

  .nav-btn-wrp{
    width: 25%;
    justify-content: flex-end;
  }

  .navbar-nav-feeliate .nav-item{
    color: #fff;
    margin-right: 45px;
    position: relative;
  }

  .navbar-nav-feeliate .nav-link{
    color: #fff;
  }

  .navbar-nav-feeliate .nav-item:last-child{
    margin-right: 0;
  }

  .navbar-nav-feeliate .nav-link:hover{
    color: #fff;
  }

  .navbar-nav-feeliate .nav-link::after{
    content: "";
    width: 0px;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0.5rem;
    background: #EA2050;
    transition: all ease-in 0.1s;
  }

  .navbar-nav-feeliate .nav-link:hover::after{
    width: 20px;
  }

  .nav-btn-wrp .nav-btn{
    width: 120px;
    height: 40px;
    text-transform: uppercase;
    border-radius: 5px;
    color: #fff;
    transition: all ease-in 0.3s;
  }

  .nav-btn-wrp .btn-signup{
    background-color: #EA2050;
    color: #fff;
  }

  .nav-btn-wrp .btn-signup:hover{
    background-color: #fff;
    color: #000;
  }

  .nav-btn-wrp .btn-login{
    background-color: #fff;
    margin-left: 20px;
    color: #000;
  }

  .nav-btn-wrp .btn-login:hover{
    background-color: #EA2050;
    color: #fff;
  }

  .navbar-feeliate .navbar-toggler-btn{
    background: none;
    border: none;
    outline: none;
    padding: 0;
  }

   .navbar-feeliate .navbar-toggler:focus{
     box-shadow: none;
   }

  .navbar-toggler-btn svg{
    width: 34px;
    height: 34px;
    color: #EA2050;
  }

  @media (max-width: 1365px) {
    .navbar-nav-feeliate .nav-item{
      margin-right: 20px;
    }
  }

   @media (max-width: 1024px) {
    .navbar-nav-feeliate{
      width: 70%;
    }

    .nav-btn-wrp{
      width: 30%;
    }
  }

  @media (max-width: 992px) {
    .navbar-feeliate{
      background-color: #000;
    }

    .navbar-nav-feeliate .nav-link:hover::after{
      width: 0px;
    }

    .navbar-nav-feeliate{
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    .navbar-nav-feeliate .nav-item{
        margin-right: 0px;
    }

    .nav-btn-wrp{
      width: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .nav-btn-wrp .nav-btn {
      width: 160px;
    }  

    .nav-btn-wrp .btn-login{
      margin: 10px 0;
    }

    .navbar-nav-feeliate{
      margin-top: 10px;
    }

    .navbar-brand img{
       width: 150px;
    }

  }

</style>