<template>
  <div class="about-us-section">
      <div class="container">
          <div class="row flex-lg-row flex-md-column">
              <div class="col-lg-6 col-md-12">
                  <div class="about-img-wrp">
                      <img class="img-fluid about-img about-top-img" src="../assets/img/about-top-img.jpg" alt="feeliate about image">
                      <img class="img-fluid about-img about-bottom-img" src="../assets/img/about-bottom-img.jpg" alt="feeliate about image">
                  </div>
              </div>
              <div class="col-lg-6 col-md-12">
                  <div class="about-wrp">
                      <HorizontalDivider />
                      <h2 class="about-header">More than affiliate network.</h2>
                      <p class="about-slogan">WE CREATE SYNERGY</p>
                      <p class="about-text">The Feeliate was founded with deep understanding of market
                        needs. Focusing our efforts on connection between premium
                        advertisers and high quality publishers is our main goal. We 
                        provide our partners from both sides with innovative 
                        platform, responsive support, quality assurance techniques 
                        and custom solutions to make campaigns profitable.</p>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import HorizontalDivider from '@/components/HorizontalDivider.vue'

export default {
    components: {HorizontalDivider}
}
</script>

<style>
    .about-us-section{
        background-color: #000;
        padding-top: 60px;
        height: 540px;
    }

    .about-img-wrp{
        position: relative;
    }

    .about-img{
        width: 280px;
        position: absolute;
        border-radius: 20px;
    }

    .about-top-img{
        top: 0;
        left: 0;
        z-index: 1;
        box-shadow: 4px 4px 11px 0px rgba(0,0,0,0.9);
    }

    .about-bottom-img{
        top: 90px;
        left: 250px;
    }

    .about-header{
        font-size: 2rem;
        font-weight: 900;
        margin-bottom: 20px;
        
    }

    .about-slogan{
        font-size: 1.5rem;
        font-weight: 900;
        color: #EA2050;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .about-text{
        font-size: 1.2rem;
        line-height: 2rem;
    }

    @media (max-width: 1024px) {
        .about-us-section{
            height: auto;
        }

        .about-img-wrp{
            height: 500px;
        }

        .about-img{
            width: 260px;
        }

        .about-top-img{
            left: 23%;
        }

        .about-bottom-img{
            left: 47%;
        }
    }

    @media (max-width: 768px) {
        .about-top-img{
            left: 15%;
        }
    }

    @media (max-width: 600px) {
        .about-top-img{
            left: 9%;
        }
    }
    @media (max-width: 414px) {
        .about-us-section{
            padding: 30px 0 30px 0;
        }

        .about-img-wrp{
            height: 450px;
        }

        .about-img{
            width: 230px;
        }

        .about-top-img{
            top: 0;
            left: 0;
        }

        .about-bottom-img{
            top: 90px;
            left: unset;
            right: 0;
        }

        .about-header{
            font-size: 1.5rem;
            margin-bottom: 10px;
        
        }

        .about-slogan{
            font-size: 1.2rem;
            margin-bottom: 10px;
        }

        .about-text{
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }

</style>