<template>
    <div class="horizontal-divider"></div>
</template>

<script>
export default {

}
</script>

<style>
    .horizontal-divider{
        width: 50px;
        height: 3px;
        margin-bottom: 20px;
        background-color: #EA2050;
    }
</style>