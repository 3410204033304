<template>
    <div class="comments-section">
        <div class="offers-header-wrp">
            <VerticalDivider />
            <h3 class="offers-header">What others say about us</h3>
            <p class="offers-slogan">Lorem ipsum dolor sit amet, consecteur adipiscing elit.</p>
        </div>

        <CommentSlider />
    </div>
</template>

<script>
import VerticalDivider from '@/components/VerticalDivider.vue'
import CommentSlider from '@/components/CommentSlider.vue'



export default {
    components: {
        VerticalDivider,
        CommentSlider
    },
}
</script>

<style>

</style>