<template>
      <div class="comment-wrp">
            <p>{{ text }}</p>
            <h5>{{ partner }}</h5>
            <span>
                <img src="@/assets/img/quote-icon.svg" alt="quote">
            </span>
      </div>
</template>

<script>
export default {
    props: ['text', 'partner' ]

}
</script>

<style>
    .comment-wrp{
        display: flex;
        flex-direction: column;
        position: relative;
        border: 1px solid #EA2050;
        border-radius: 5px;
        padding: 30px;
        margin-top: 30px;
    }

    .comment-wrp span{
        position: absolute;
        top: -30px;
        left: 30px;
    }

    @media (max-width: 992px) {
        .comment-wrp {
            padding: 15px;
        }

        .comment-wrp span {
            position: absolute;
            top: -25px;
            left: 10px;
        }

        .comment-wrp span img {
            width: 40px;
        }
    }

</style>