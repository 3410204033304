<template>
  <div id="footer" class="footer-section">
      <div class="container">
          <div class="row">
              <div class="col-lg-4 col-12">
                  <div class="footer-brand">
                      <img src="@/assets/img/feeliate-logo.svg" alt="feeliate logo">
                  </div>
                  <div class="footer-info">
                      <span>Jupiter Media B.V.</span>
                      <span>Mondriaantoren, 30th Floor, Amstelplein 62.</span>
                      <span>1096 BC Amsterdam, Netherlands</span>
                      <br>
                      <span>Chamber of Commerce: 70543046</span>
                      <span>VAT-number: NL8583.67.671.B01</span>
                      <span>IBAN: NL45 ABNA 0825 6826 65</span>
                      <span>BIC: ABNANL2A</span>
                  </div>
                  <div class="footer-social">
                      <ul class="d-flex mt-3">
                          <li>
                              <a href="https://www.facebook.com/feeliatenetwork">
                                  <img src="@/assets/img/facebook-icon.svg" alt="feeliate facebook">
                              </a>
                          </li>
                          <li>
                              <a href="https://www.facebook.com/feeliatenetwork">
                                  <img src="@/assets/img/linkedin-icon.svg" alt="feeliate linkedin">
                              </a>
                          </li>
                          <li>
                              <a href="https://www.facebook.com/feeliatenetwork">
                                  <img src="@/assets/img/instagram-icon.svg" alt="feeliate instagram">
                              </a>
                          </li>
                          <li>
                              <a href="https://www.facebook.com/feeliatenetwork">
                                  <img src="@/assets/img/twitter-icon.svg" alt="feeliate twitter">
                              </a>
                          </li>
                      </ul>
                  </div>
              </div>
              <div class="col-lg-2 footer-nav offset-md-2 d-none d-lg-block">
                  <h5>Join Feeliate</h5>
                  <ul>
                      <li><a href="https://partners.feeliate.com/">Affiliate signup</a></li>
                      <li><a href="https://partners.feeliate.com/">Advertiser signup</a></li>
                      <li><a href="https://partners.feeliate.com/">Login</a></li>
                  </ul>
              </div>
              <div class="col-lg-2 footer-nav d-none d-lg-block">
                  <h5>Resources</h5>
                  <ul>
                      <li><a href="/">Home</a></li>
                      <li><a @click="scroll('#offers')" href="#offers">Offers</a></li>
                      <li><a @click="scroll('#affiliates')" href="#affiliates">Affiliates</a></li>
                      <li><a @click="scroll('#advertisers')" href="#advertisers">Advertiser</a></li>
                      <li><a @click="scroll('#footer')" href="#footer">Contact</a></li>
                      <li><a @click="scroll('#offers')" href="#">Terms and Conditions</a></li>
                      <li><a @click="scroll('#offers')" href="#">Privacy Policy</a></li>
                  </ul>
              </div>
              <div class="col-lg-2 footer-nav d-none d-lg-block">
                  <h5>Company</h5>
                  <ul>
                      <li><a href="#">About us</a></li>
                      <li><a href="#">Careers</a></li>
                      <li><a href="#">Team</a></li>
                      <li><a href="#">Gallery</a></li>
                  </ul>
              </div>
          </div>
          <div class="row">
              <div class="copyright">
                  Copyright © 2021 Feeliate, All Rights Reserved
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    methods: {
    
    scroll(id) {  
      document.getElementById(id).scrollIntoView({
        behavior: "smooth"
      });
    }
  }
}
</script>

<style scoped>
    .footer-section{
        margin-top: 60px;
        background-color: #1F1F1F;
        padding-top: 30px;
    }

    .footer-section ul, li{
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .footer-section a{
        color: #fff;
        text-decoration: none;
    }

    .footer-section h5{
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 30px;
    }

    .footer-brand{
        margin-bottom: 30px;
    }

    .footer-brand img{
        height: 30px;
    }

    .footer-info span {
        display: block;
    }

    .footer-social li{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border: 2px solid #EA2050;
        border-radius: 50px;
        margin-right: 15px;
        margin-bottom: 15px;
    }

    .footer-social li img{
        width: 25px;
        height: 25px;
    }

    .footer-nav li{
        margin-bottom: 5px;
    }

    .copyright{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        padding: 20px 0;
        background-color: #252525;
    }

</style>