<template>
  <div id="advertisers" class="benefits-section">
      <div class="container">
        <VerticalDivider />
        <div class="benefits-header">
          <HorizontalDivider />
          <div class="benefits-tittle-wrp">
            <h3>Advertisers</h3>
            <a class="btn sign-btn" href="https://feelrobotics.hasoffers.com/">Sign up now</a>
          </div>
          <div class="benefits-slogan">
            We know how to monetize your traffic  
          </div>
          <p class="benefits-description">
            Curabitur gravida placerat tellus, vitae rutrum eros. Aenean cursus vel nulla vel 
            sagittis. Suspendisse tempus viverra commodo.  
          </p>
        </div>
        <div class="benefits-body">
          <div class="row gx-3 gy-2 flex-sm-row flex-column justify-content-md-center">
            <BenefitsCard 
              v-for="benefit in benefits"
              :key ="benefit.id"
              :src ="benefit.src"
              :title="benefit.title"
              :text="benefit.text" 
            ></BenefitsCard>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import BenefitsCard from '@/components/BenefitsCard.vue'
import VerticalDivider from '@/components/VerticalDivider.vue'
import HorizontalDivider from '@/components/HorizontalDivider.vue'


export default {
  components: { BenefitsCard, VerticalDivider, HorizontalDivider },
      data() {
       return {
           benefits: [{
             id: 1,
             src: require('@/assets/img/benefit-payout.jpg'),
             title: '100% Payouts',
             text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla lobortis faucibus purus idvestibulum. Mauris ut mi a mauris placerat"
           }, {
             id: 2,
             src: require('@/assets/img/benefit-creatives.jpg'),
             title: 'Ready-use creatives',
             text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla lobortis faucibus purus idvestibulum. Mauris ut mi a mauris placerat"
           }, {
             id: 3,
             src: require('@/assets/img/benefit-statistic.jpg'),
             title: 'Detailed statistics',
             text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla lobortis faucibus purus idvestibulum. Mauris ut mi a mauris placerat"
           },
           {
             id: 4,
             src: require('@/assets/img/benefit-statistic.jpg'),
             title: 'Detailed statistics',
             text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla lobortis faucibus purus idvestibulum. Mauris ut mi a mauris placerat"
           }]
       }
    }

}
</script>

<style>
    .benefits-header-reverse{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .benefits-tittle-wrp-reverse{
        width: 100%;
        flex-direction: row-reverse;
    }

     @media (max-width: 992px) {

     }

    @media (max-width: 414px) {

     }
</style>