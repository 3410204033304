<template>
  <div class="vertical-devider"></div>
</template>

<script>
export default {

}
</script>

<style>
    .vertical-devider{
        width: 4px;
        height: 50px;
        background-color: #EA2050;
        margin: 60px auto;
    }

    @media (max-width: 992px) {
        .vertical-devider{
            height: 30px;
            margin: 30px auto;
        }
    }
</style>