<template>
  <div class="about-hero-section">
      <div class="container">
          <div class="about-hero-section-content">
              <h1>We belive in the power of “What if?”</h1>
              <HorizontalDivider 
                style="margin: 30px auto"
              />
              <p>The Feeliate was founded with deep understanding of market needs. Focusing our efforts on connection between premium advertisers and high quality publishers is our main goal. </p>
          </div>
      </div>
  </div>
</template>

<script>
import HorizontalDivider from '@/components/HorizontalDivider.vue'

export default {
    components: { HorizontalDivider }
}
</script>

<style>
    .about-hero-section{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 498px;
        background: center / cover no-repeat url(../assets/img/about-hero-bg.jpg);
        width: 100%;;
        padding-top: 90px;
        margin-top: -90px;
        transition: all ease-in-out 0.15s;
        text-align: center;
    }

    .about-hero-section-content{
        width: 60%;
        margin: 0 auto;
    }

    .about-hero-section h1{
        font-size: 3.75rem;
        font-weight: 800;
    }

    .about-hero-section p{
        font-size: 1.25rem;
    }

    @media (max-width: 1024px) {
        .about-hero-section-content{
            width: 80%;
        }

        .about-hero-section h1{
            font-size: 3rem;
        }

        .about-hero-section p{
            font-size: 1rem;
        }
    }

    @media (max-width: 414px) {
        .about-hero-section-content{
            width: 100%;
        }

        .about-hero-section h1{
            font-size: 2rem;
        }
    }
</style>