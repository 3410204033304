<template>
    <VerticalDivider />
    <div class="team-section">
        <div class="container">
            <div class="team-section-header">

            </div>
            <div class="team">
                <div class="row">
                    <TeamMember 
                        v-for="member in members" 
                        :key = "member.id"
                        :src="member.src" 
                        :alt="member.alt" 
                        :name="member.name"
                        :position="member.position" 
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VerticalDivider from '@/components/VerticalDivider.vue'
    import TeamMember from '@/components/TeamMember'

    export default {
        components: {
            TeamMember,
            VerticalDivider
        },
        data() {
            return {
                members: [
                    {
                        id: '1',
                        src: require('@/assets/img/team-nils.jpg'),
                        alt: 'Nils Jansma Feeliate',
                        name: 'Nils Jansma',
                        position: 'Business Director'
                },{
                        id: '2',
                        src: require('@/assets/img/team-viktoria.jpg'),
                        alt: 'Viktoriia Zhyliak Feeliate',
                        name: 'Viktoriia Zhyliak',
                        position: 'Affiliate Manager'
                },{
                        id: '3',
                        src: require('@/assets/img/team-tamas.jpg'),
                        alt: 'Tamas Vekony Feeliate',
                        name: 'Tamas Vekony',
                        position: 'Affiliate Manager'
                },{
                        id: '4',
                        src: require('@/assets/img/team-tamas.jpg'),
                        alt: 'Kathleen Down Feeliate',
                        name: 'Kathleen Down',
                        position: 'Affiliate Manager'
                },{
                        id: '5',
                        src: require('@/assets/img/team-leks.jpg'),
                        alt: 'Leks Betlem Feeliate',
                        name: 'Leks Betlem',
                        position: 'Advertising Manager'
                },{
                        id: '6',
                        src: require('@/assets/img/team-vladimir.jpg'),
                        alt: 'Vladimir Bulavin Feeliate',
                        name: 'Vladimir Bulavin',
                        position: 'Frontend Engineer'
                } 
                ]
            }
        }

    }
</script>

<style>

</style>