<template>
  <splide :options="options" :slides="slides">
    <splide-slide v-for="slide in slides" :key="slide.id">
        <Comment 
            :id = "slide.id"
            :text = "slide.text"
            :partner= "slide.partner"
        />
    </splide-slide>
  </splide>
</template>

<script>
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import Comment from '@/components/Comment.vue';
    import '@splidejs/splide/dist/css/themes/splide-default.min.css';

  export default {
        components: {
            Splide,
            SplideSlide,
            Comment
        },
	  data() {
		  return {
			  slides: [
					{ 
                        id: 1,
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                        partner: '- Nomud Ali, San Francisco'    
                    },
                    { 
                        id: 2,
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                        partner: '- Nomud Ali, San Francisco'    
                    },
                    { 
                        id: 3,
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                        partner: '- Nomud Ali, San Francisco'    
                    },
                    { 
                        id: 4,
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                        partner: '- Nomud Ali, San Francisco'    
                    },
                    { 
                        id: 5,
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                        partner: '- Nomud Ali, San Francisco'    
                    },
                    { 
                        id: 6,
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                        partner: '- Nomud Ali, San Francisco'    
                    },
                    { 
                        id: 7,
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                        partner: '- Nomud Ali, San Francisco'    
                    }
				],
                options: {
                    type: 'loop',
                    perPage: 4,
                    perMove: 1,
                    padding: {
                        right: '7rem',
                        left: '7rem'
                    },
                    gap: '2.5rem',
                    autoplay: true,
                        breakpoints: {
                            3840: {
                                perPage: 2,
                                padding: {
                                    right: '50rem',
                                    left: '50rem'
                                }
                            },
                            1920: {
                                perPage: 4,
                                padding: {
                                    right: '7rem',
                                    left: '7rem'
                                }
                            },
                            1440: {
                                perPage: 3,
                                padding: {
                                    right: '7rem',
                                    left: '7rem'
                                },
                                gap: '2rem',
                            },
                            992: {
                                perPage: 2,
                                padding: {
                                    right: '4rem',
                                    left: '4rem'
                                },
                                gap: '1rem',
                                
                            },
                            540: {
                                perPage: 1,
                                padding: {
                                    right: '1rem',
                                    left: '1rem'
                                },
                                gap: '1rem',
                            },
                            414: {
                                perPage: 1,
                                padding: {
                                    right: '2rem',
                                    left: '2rem'
                                },
                                gap: '1rem',
                            }
    }
			  },
		  };
	  },
  }
</script>

<style>
    .splide__pagination{
        bottom: -1.5em;
    }
</style>