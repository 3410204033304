<template>
    <Hero />
    <About />
    <Offers />
    <Affiliates />
    <Advertisers />
    <Comments />
    <Partners />
</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero.vue'
import About from '@/components/About.vue'
import Offers from '@/components/Offers.vue'
import Affiliates from '@/components/Affiliates.vue'
import Advertisers from '@/components/Advertisers.vue'
import Comments from '@/components/Comments.vue'
import Partners from '@/components/Partners.vue'

export default {
  name: 'Home',
  components: {
    Hero,
    About,
    Offers,
    Affiliates,
    Advertisers,
    Comments,
    Partners
  }
}
</script>
