<template>
  <div v-for="offer in offers" :key="offer.description" class="col-lg-4 col-md-10 col-12">
      <div class="offer-wrp">
          <img class="img-fluid" :src="offer.src" :alt="offer.alt">
          <div class="offer-description">
              <div class="offer-header">{{ offer.header }}</div>
              <div class="offer-text">{{ offer.text }}</div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props: ['offerHeader', 'offerText'],
    data() {
       return {
           offers: [
               {
                   header: "Streaming services", 
                   text: "High quality interactive streaming services since 2003", 
                   src: require('@/assets/img/offer-cams.jpg'), 
                   alt: "Feeliate video streaming services"},
               {
                   header: "E-commerce", 
                   text: "Top notch interactive toys for couples and male/female individuals", 
                   src: require('@/assets/img/offer-ecom.jpg'), 
                   alt: "Feeliate e-commerce"},
               {
                   header: "Mainstream", 
                   text: "Revolutionary haptic devices that bring loved ones closer together", 
                   src: require('@/assets/img/offer-mainstream.jpg'), 
                   alt: "Feeliate mainstream"},
           ]
       }
    }
}
</script>

<style>
    .offer-wrp{
        position: relative;
        height: 426.66px;
        overflow: hidden;
    }

    .offer-description{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0 15px 30px;
        background: rgb(17,17,17);
        background: linear-gradient(0deg, rgba(17,17,17,1) 0%, rgba(13,13,13,0.65) 50%, rgba(0,0,0,0) 100%);
    }

    .offer-header{
        font-size: 2rem; 
        font-weight: 600;
        margin-top: 130px;
    }

    .offer-text{
        font-size: 1rem;
    }

    @media (max-width: 1366px) {
        .offer-header{
            font-size: 1.8rem; 
        }
        .offer-wrp{
            height: 366px;
            overflow: hidden;
        }
    }

    @media (max-width: 992px) {
        .offer-wrp{
            height: 578px;
            overflow: hidden;
        }

        .offer-header{
            font-size: 2.4rem; 
            margin-top: 90px;
        }

        .offer-text{
            font-size: 1.4rem;
        }


    }

    @media (max-width: 414px) {
        .offer-wrp{
            height: 390px;
            overflow: hidden;
        }   

        .offer-header{
            font-size: 1.5rem; 
            margin-top: 130px;
        }

        .offer-text{
            font-size: 1rem;
        }
    }

    @media (max-width: 320px) {
        .offer-wrp{
            height: 296px;
        }   

        .offer-header{
            font-size: 1.5rem; 
            margin-top: 130px;
        }
    }

</style>