<template>
  <AboutHero />
  <AboutBlock 
    v-for="advantage in advantages"
    :key = "advantage.id"
    :divider = "advantage.divider"
    :align = "advantage.align"
    :direction = "advantage.direction"
    :float = "advantage.float"
    :title = "advantage.title"
    :description = "advantage.description"
    :src = "advantage.src"
    :alt = "advantage.alt"
    />
    <Team />
</template>

<script>
import AboutHero from '@/components/AboutHero.vue'
import AboutBlock from '@/components/AboutBlock.vue'
import Team from '@/components/Team.vue'

export default {
  name: 'About',
  components: { 
    AboutHero,
    AboutBlock,
    Team
  },
  data() {
    return {
      advantages: [{
        id: 1,
        divider: 'display: none',
        direction: 'flex-direction: row',
        align: 'justify-content: flex-end',
        title: 'Our Mission',
        description: 'To provide a comprehensive, all-in-one digital marketing and design solution to businesses of all sizes. We strive to ensure that every campaign we deliver on will measurably help our clients impact their industries and grow their business. We hope that the pursuit of this mission helps us grow alongside our clients.',
        src: require('@/assets/img/our-mission.jpg'),
        alt: 'feeliate mission',
      },{
        id: 2,
        divider: 'display: block',
        direction: 'flex-direction: row-reverse',
        align: 'justify-content: flex-start',
        title: 'Our Expertise',
        description: 'Our expertise consists of, but is not limited to: 1. Developing an advertising strategy management plan for leads  conversions 2. Sales funnel and E-commerce site development 3. Chatbot development and strategy 4. Ongoing organic content creation 5. Full social media management community management',
        src: require('@/assets/img/our-expertise.jpg'),
        alt: 'feeliate expertise',
      },{
        id: 3,
        divider: 'display: block',
        direction: 'flex-direction: row',
        align: 'justify-content: flex-end',
        title: 'Team synergy ',
        description: 'The Feeliate was founded with deep understanding of market needs. Focusing our efforts on connection between premium advertisers and high quality publishers is our main goal. We provide our partners from both sides with innovative platform, responsive support, quality assurance techniques and custom solutions to make campaigns profitable.',
        src: require('@/assets/img/team-synergy.jpg'),
        alt: 'feeliate team synergy',
      }]
    }
  }


}
</script>

<style>

</style>
